<template>
  <div class="studyDetails">
    <div v-if="loading || !study">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col class="mt-5">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="study.sigleLegam" :pretitle="this.$t('StudyDetails')" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.study.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.study.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.study.modify') && $store.getters.checkRole('legam.bib.study.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <article>
          <div class="mb-4" v-if="config.editable">
            <p class="mb-1">BIB Labels</p>
            <BibLabelSelect v-if="editMode" :selected="study.biblabels.map(biblabel => biblabel.id)" @select="selectBibLabels" />
            <span v-else>
              <b-badge v-for="biblabel in study.biblabels" :key="biblabel.id" href="#" class="mr-1" variant="secondary">{{ biblabel.label }}</b-badge>
            </span>
          </div>
          <b-row>
            <b-col>
              <h6>{{ $t('SigleLEGaM') }}</h6>
              <b-form-input
                id="sigle-legam"
                v-model="study.sigleLegam"
                placeholder="Sigle LEGaM"
                v-if="editMode"
              ></b-form-input>
              <span v-else v-html="study.sigleLegam"></span>
            </b-col>
            <b-col>
              <h6>{{ $t('Color') }}</h6>
              <Select2 v-if="editMode" v-model="study.feColor" :options="colorOptions" @select="selectColor($event)" :settings="selectColorConfig" />
              <span v-else>
                <span :style="'background-color: ' + study.feColor" class="color-preview"></span> {{ study.feColor }}
              </span>
              <!-- <h6>Alternative Sigles</h6>
              <div
                v-for="siglesource in study.siglesources"
                :key="siglesource.siglesourceCode"
                v-html="`${siglesource.alternativeSigle} - ${siglesource.siglesourceCode}`"
              ></div> -->
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <h6>{{ $t('BibDescription') }}</h6>
              <b-form-textarea
                id="bib-description"
                placeholder="BIB Description"
                v-model="study.bibDescription"
                rows="3"
                max-rows="6"
                v-if="editMode"
              ></b-form-textarea>
              <span v-else v-html="study.bibDescription"></span>
            </b-col>
            <b-col>
              <h6>{{ $t('CommentGeneral') }}</h6>
              <b-form-textarea
                id="comment-general"
                placeholder="Comment General"
                v-model="study.commentGeneral"
                rows="3"
                max-rows="6"
                v-if="editMode"
              ></b-form-textarea>
              <span v-else class="white-space-pre-line" v-html="study.commentGeneral"></span>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-3">
            <b-col>
            </b-col>
            <b-col>
            </b-col>
          </b-row> -->

          <b-button @click="showUnstructData = !showUnstructData" size="sm" class="mb-2 mt-4" v-if="study.unstruct && config.editable">
            <span v-if="showUnstructData">Hide unstruct data</span>
            <span v-else>Show unstruct data</span>
          </b-button>

          <div v-show="showUnstructData" v-if="study.unstruct">
            <h6 class="with-line mt-4">Unstruct</h6>
            <b-row cols="2">
              <b-col v-for="key in Object.keys(filteredUnstructData)" :key="key">
                <h6>{{ key.replace(/_/ig, ' ') }}</h6>
                <pre>{{ study.unstruct[key] }}</pre>
              </b-col>
            </b-row>
          </div>

          <hr>

          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 class="text-left">
                  Scholars <small>({{ study.authors.length }} {{ study.authors.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion-1" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-author>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-author" title="Add scholar" ok-only ok-variant="secondary" size="lg">
                    <AuthorAutocomplete @select="addAuthor" />
                  </b-modal>
                  <AuthorTable
                    :showTotal="false"
                    :authors="study.authors"
                    @delete="deleteAuthor"
                    :editMode="editMode"
                    v-if="study.authors.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 class="text-left">
                  Texts <small>({{ study.texts.length }} {{ study.texts.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-text>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-text" title="Add study" ok-only ok-variant="secondary" size="lg">
                    <TextAutocomplete @select="addText" />
                  </b-modal>
                  <TextTable
                    :showTotal="false"
                    :texts="study.texts"
                    :editMode="editMode"
                    @delete="deleteText"
                    v-if="study.texts.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 class="text-left">
                  Manuscripts <small>({{ study.manuscripts.length }} {{ study.manuscripts.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                    <ManuscriptAutocomplete @select="addManuscript" />
                  </b-modal>
                  <ManuscriptTable
                    :showTotal="false"
                    :manuscripts="study.manuscripts"
                    @delete="deleteManuscript"
                    :editMode="editMode"
                    v-if="study.manuscripts.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-4 class="text-left">
                  Editions <small>({{ study.editions.length }} {{ study.editions.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-edition>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-edition" title="Add edition" ok-only ok-variant="secondary" size="lg">
                    <EditionAutocomplete @select="addEdition" />
                  </b-modal>
                  <EditionTable
                    :showTotal="false"
                    :editions="study.editions"
                    @delete="deleteEdition"
                    :editMode="editMode"
                    v-if="study.editions.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-5 class="text-left">
                  Historical Authors <small>({{ study.authorhists.length }} {{ study.authorhists.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="my-accordion-5" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-authorhist>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-authorhist" title="Add authorhist" ok-only ok-variant="secondary" size="lg">
                    <AuthorhistAutocomplete @select="addAuthorhist" />
                  </b-modal>
                  <AuthorhistTable
                    :showTotal="false"
                    :authorhists="study.authorhists"
                    @delete="deleteAuthorhist"
                    :editMode="editMode"
                    v-if="study.authorhists.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-6 class="text-left">
                  Reviews <small>({{ study.reviews.length }} {{ study.reviews.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-6" accordion="my-accordion-6" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-review>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-review" title="Add review" ok-only ok-variant="secondary" size="lg">
                    <ReviewAutocomplete @select="addReview" />
                  </b-modal>
                  <ReviewTable
                    :showTotal="false"
                    :reviews="study.reviews"
                    @delete="deleteReview"
                    :editMode="editMode"
                    v-if="study.reviews.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-7 class="text-left">
                  Alternative Sigles <small>({{ study.siglesources.length }} {{ study.siglesources.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-7" accordion="my-accordion-7" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-siglesource>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-siglesource" title="Add alternative sigle" ok-only ok-variant="secondary" @ok="addSigleSource" size="lg">
                    <SigleSourceRelAutocomplete :sigleSourceRel="modalSigleSourceRel" />
                  </b-modal>
                  <SigleSourceRelTable
                    :showTotal="false"
                    :siglesourcesRel="study.siglesources"
                    @delete="deleteSigleSource"
                    @edit="editSigleSource"
                    :editMode="editMode"
                    v-if="study.siglesources.length"
                    :key="indexSigleSource"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-8 class="text-left">
                  Links <small>({{ study.links.length }} {{ study.links.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-8" accordion="my-accordion-8" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-link>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-link" title="Add link" ok-only ok-variant="secondary" @ok="addLink" size="lg">
                    <LinkAutocomplete :link="modalLink" />
                  </b-modal>
                  <LinkTable
                    :showTotal="false"
                    :links="study.links"
                    @delete="deleteLink"
                    @edit="editLink"
                    :editMode="editMode"
                    v-if="study.links.length"
                    :key="indexLink"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </article>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'study'" :objectId="studyId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style>
  .color-preview {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }
  .card-body .clearfix .float-right {
    max-width: unset;
  }
  .card-body .clearfix .float-right ul {
    float: right;
  }
  .card-body .clearfix .pagination-sm .page-item {
    max-width: 50px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ManuscriptTable from '@/components/Bibliography/ManuscriptTable.vue'
  import TextTable from '@/components/Bibliography/TextTable.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import EditionTable from '@/components/Bibliography/EditionTable.vue'
  import AuthorTable from '@/components/Bibliography/AuthorTable.vue'
  import AuthorhistTable from '@/components/Bibliography/AuthorhistTable.vue'
  import ReviewTable from '@/components/Bibliography/ReviewTable.vue'
  import ReviewAutocomplete from '@/components/Bibliography/ReviewAutocomplete.vue'
  import AuthorAutocomplete from '@/components/Bibliography/AuthorAutocomplete.vue'
  import TextAutocomplete from '@/components/Bibliography/TextAutocomplete.vue'
  import ManuscriptAutocomplete from '@/components/Bibliography/ManuscriptAutocomplete.vue'
  import EditionAutocomplete from '@/components/Bibliography/EditionAutocomplete.vue'
  import AuthorhistAutocomplete from '@/components/Bibliography/AuthorhistAutocomplete.vue'
  import BibLabelSelect from '@/components/Bibliography/BibLabelSelect.vue'
  import SigleSourceRelAutocomplete from '@/components/Bibliography/SigleSourceRelAutocomplete.vue'
  import SigleSourceRelTable from '@/components/Bibliography/SigleSourceRelTable.vue'
  import LinkTable from '@/components/Bibliography/LinkTable.vue'
  import LinkAutocomplete from '@/components/Bibliography/LinkAutocomplete.vue'
  import HelpStore from '@/store/helpstore.js'
  import Select2 from 'v-select2-component'
  import config from '@/config.js'
  import $ from 'jquery'

  export default {
    name: 'StudyDetails',
    data() {
      return {
        studyId: this.$route.params.id,
        loading: true,
        editMode: false,
        config: config,
        index: 0,
        showUnstructData: false,
        indexSigleSource: 0,
        modalSigleSourceRel: null,
        indexLink: 0,
        modalLink: null,
        colorsList: [
          "AliceBlue","AntiqueWhite","Aqua","Aquamarine","Azure","Beige","Bisque","Black","BlanchedAlmond","Blue",
          "BlueViolet","Brown","BurlyWood","CadetBlue","Chartreuse","Chocolate","Coral","CornflowerBlue","Cornsilk",
          "Crimson","Cyan","DarkBlue","DarkCyan","DarkGoldenRod","DarkGray","DarkGrey","DarkGreen","DarkKhaki",
          "DarkMagenta","DarkOliveGreen","DarkOrange","DarkOrchid","DarkRed","DarkSalmon","DarkSeaGreen",
          "DarkSlateBlue","DarkSlateGray","DarkSlateGrey","DarkTurquoise","DarkViolet","DeepPink","DeepSkyBlue",
          "DimGray","DimGrey","DodgerBlue","FireBrick","FloralWhite","ForestGreen","Fuchsia","Gainsboro","GhostWhite",
          "Gold","GoldenRod","Gray","Grey","Green","GreenYellow","HoneyDew","HotPink","IndianRed ","Indigo  ","Ivory",
          "Khaki","Lavender","LavenderBlush","LawnGreen","LemonChiffon","LightBlue","LightCoral","LightCyan",
          "LightGoldenRodYellow","LightGray","LightGrey","LightGreen","LightPink","LightSalmon","LightSeaGreen",
          "LightSkyBlue","LightSlateGray","LightSlateGrey","LightSteelBlue","LightYellow","Lime","LimeGreen","Linen",
          "Magenta","Maroon","MediumAquaMarine","MediumBlue","MediumOrchid","MediumPurple","MediumSeaGreen",
          "MediumSlateBlue","MediumSpringGreen","MediumTurquoise","MediumVioletRed","MidnightBlue","MintCream",
          "MistyRose","Moccasin","NavajoWhite","Navy","OldLace","Olive","OliveDrab","Orange","OrangeRed","Orchid",
          "PaleGoldenRod","PaleGreen","PaleTurquoise","PaleVioletRed","PapayaWhip","PeachPuff","Peru","Pink","Plum",
          "PowderBlue","Purple","RebeccaPurple","Red","RosyBrown","RoyalBlue","SaddleBrown","Salmon","SandyBrown",
          "SeaGreen","SeaShell","Sienna","Silver","SkyBlue","SlateBlue","SlateGray","SlateGrey","Snow","SpringGreen",
          "SteelBlue","Tan","Teal","Thistle","Tomato","Turquoise","Violet","Wheat","White","WhiteSmoke","Yellow",
          "YellowGreen"
        ]
      }
    },
    components: {
      PageTitle,
      ManuscriptTable,
      TextTable,
      EditionTable,
      AuthorTable,
      AuthorhistTable,
      AuthorAutocomplete,
      TextAutocomplete,
      ManuscriptAutocomplete,
      EditionAutocomplete,
      AuthorhistAutocomplete,
      ChangeLogsTable,
      BibLabelSelect,
      Select2,
      ReviewTable,
      ReviewAutocomplete,
      SigleSourceRelAutocomplete,
      SigleSourceRelTable,
      LinkTable,
      LinkAutocomplete,
    },
    computed: {
      ...mapState({
        study: state => state.study.study
      }),
      authorhists(){
        return this.study.authorhists.map(authorhist => {
          return {
            sigleLegam: authorhist.sigleLegam,
            // sigleOther: authorhist.sigleOther,
            datesOfLife: authorhist.datesOfLife,
            datesOfActivity: authorhist.datesOfActivity,
            commentGeneral: authorhist.commentGeneral
          }
        })
      },
      filteredUnstructData(){
        let retval = {}
        Object.keys(this.study.unstruct).forEach(key => {
          if (key != 'unused_attributes'){
            retval[key] = this.study.unstruct[key]
          }
        })
        return retval
      },
      selectColorConfig(){
        return {
          placeholder: "Select a color",
          allowClear: true,
          width: '100%',
          templateResult: this.formatColor,
          templateSelection: this.formatColor
        }
      },
      colorOptions(){
        return this.colorsList.map(color => {
          return {
            id: color,
            text: color
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    mounted() {
      this.$store.dispatch('loadStudy', this.studyId)
    },
    watch: {
      'studyId'() {
        this.loading = true
        this.$store.dispatch('loadStudy', this.studyId)
      },
      'study'() {
        this.loading = false
      }
    },
    methods: {
      formatColor(item){
        return $(`<span><span
            style="background-color: ${item.id}; display: inline-block; width: 12px; height: 12px; border-radius: 3px;"
          ></span> ${item.id}</span>`)
      },
      addAuthor(author){
        this.$bvModal.hide('modal-add-author')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Author added`
        })
        this.study.authors.push(author)
      },
      deleteAuthor(authorId){
        this.study.authors = this.study.authors.filter(author => author.id != authorId)
      },
      addSigleSource(){
        this.modalSigleSourceRel = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.study.siglesources[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.study.siglesources.push(HelpStore.item);
          }
          this.indexSigleSource++;
        }
      },
      deleteSigleSource(sigleSourceRelId){
        this.study.siglesources = this.study.siglesources.filter(sigleSourceRel => sigleSourceRel.id != sigleSourceRelId)
      },
      editSigleSource(sigleSourceRel){
        this.modalSigleSourceRel = sigleSourceRel;
        this.$bvModal.show('modal-add-siglesource');
      },
      addReview(review){
        this.$bvModal.hide('modal-add-review')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Review added`
        })
        this.study.reviews.push(review)
      },
      deleteReview(reviewId){
        this.study.reviews = this.study.reviews.filter(review => review.id != reviewId)
      },
      addText(text){
        this.$bvModal.hide('modal-add-text')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Text added`
        })
        this.study.texts.push(text)
      },
      selectBibLabels(biblabels){
        this.study['biblabels'] = biblabels
      },
      deleteText(textId){
        this.study.texts = this.study.texts.filter(text => text.id != textId)
      },
      addManuscript(manuscript){
        this.$bvModal.hide('modal-add-manuscript')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Manuscript added`
        })
        this.study.manuscripts.push(manuscript)
      },
      deleteManuscript(manuscriptId){
        this.study.manuscripts = this.study.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },
      addEdition(edition){
        this.$bvModal.hide('modal-add-edition')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Edition added`
        })
        this.study.editions.push(edition)
      },
      deleteEdition(editionId){
        this.study.editions = this.study.editions.filter(edition => edition.id != editionId)
      },
      addAuthorhist(authorhist){
        this.$bvModal.hide('modal-add-authorhist')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Authorhist added`
        })
        this.study.authorhists.push(authorhist)
      },
      deleteAuthorhist(authorhistId){
        this.study.authorhists = this.study.authorhists.filter(authorhist => authorhist.id != authorhistId)
      },
      addLink(){
        this.modalLink = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.study.links[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.study.links.push(HelpStore.item);
          }
          this.indexLink++;
        }
      },
      deleteLink(linkId){
        this.study.links = this.study.links.filter(link => link.id != linkId)
      },
      editLink(link){
        this.modalLink = link;
        this.$bvModal.show('modal-add-link');
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadStudy', this.studyId)
      },
      selectColor(event){
        this.study.feColor = null
        if (event.selected){
          this.study.feColor = event.id
        }
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveStudy', this.study).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteStudy', this.study).then(() => {
              router.push('/studies')
            })
          }
        })
      }
    }
  }
</script>
